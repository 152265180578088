import React from "react"
import Layout from '../components/layout2'

export default function Clients() {
  
  return <div >
      <Layout>
          <div style={{height: "100vh", background: "#202124"}}>
          <h1>Thanks, we will be in touch.</h1>

          </div>
 

  </Layout>
  </div>
}
